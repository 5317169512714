<template>
    <div class="container">
        <content-title :nav="nav"></content-title>
        <el-row>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="姓名">
                    <el-input v-model="name"></el-input>
                </el-form-item>
                <el-form-item label="电话">
                    <el-input v-model="phone"></el-input>
                </el-form-item>
                <el-form-item label="教学类目">
                    <el-select v-model="teaching_categories" clearable name="categories_child" remote style="width: 150px">
                        <el-option v-for="item in listPageChild" :key="item.id" :label="item.name"
                            :value="item.name"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="DataList" :fit="true" :cell-style="{ 'text-align': 'center' }"
                :header-cell-style="{ 'text-align': 'center' }" v-loading="is_loading">
                <el-table-column type="index"></el-table-column>
                <el-table-column label="教练名称" prop="name"></el-table-column>
                <el-table-column label="电话" prop="phone"></el-table-column>
                <el-table-column label="教学类目" prop="teaching_categories"></el-table-column>
                <el-table-column label="性别" prop="sex">
                    <template slot-scope="{ row }">
                        <p v-if="row.sex == 0">未知</p>
                        <p v-if="row.sex == 1">男</p>
                        <p v-if="row.sex == 2">女</p>
                    </template>
                </el-table-column>
                <el-table-column label="保证金" prop="bond"></el-table-column>
                <el-table-column label="操作" width="250px">
                    <template slot-scope="{ row }">
                        <div class="flex flex-wrap">
                            <el-button type="primary" @click="toDetails(row)" size="small">查看详情</el-button>
                            <el-button style="margin-left: 10px;" type="warning" @click="coach_id = row.id, popup = true"
                                size="small">扣除保证金</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <paging-fy @currentPageChange="changeCurrentPage" :currentPage="currentPage" :total="Total"></paging-fy>
        </el-row>
        <el-dialog title="扣除保证金" :visible.sync="popup" width="400px">
            <el-form ref="form" rules :model="form" label-width="80px">
                <el-form-item label="扣除金额" required>
                    <el-input v-model="form.bond" placeholder="请输入扣除金额"></el-input>
                </el-form-item>
                <el-form-item label="扣除说明" required>
                    <el-input type="textarea" :rows="3" placeholder="请输入扣除说明" v-model="form.remark">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="popup = false">取 消</el-button>
                <el-button type="primary" @click="buckle">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import mechanismSelect from "../../components/select/mechanismSelect.vue";
import userSelect from "../../components/select/userSelect.vue";
export default {
    components: {
        mechanismSelect,
        userSelect,
    },
    data() {
        return {
            form: {
                bond: '',
                remark: '',
            },
            nav: { firstNav: "教练管理中心", secondNav: "教练保证金" },
            DataList: [],
            is_loading: true,
            currentPage: 1,
            Total: 0,
            options: [
                {
                    value: 0,
                    label: "信息待提交",
                },
                {
                    value: 1,
                    label: "待审核",
                },
                {
                    value: 2,
                    label: "已通过",
                },
                {
                    value: 3,
                    label: "已拒绝",
                },
            ],
            coach_id: "",
            mechanism_id: "",
            price: "",
            listPageChild: [],
            teaching_categories: '',
            name: '',
            phone: '',
            popup: false,
        };
    },
    mounted() {
        this.getList();
        this.GetListPageChild()
    },
    methods: {
        buckle() {
            if (Object.values(this.form).includes('')) {
                this.$message({
                    type: "error",
                    message: "请补全信息",
                });
                return
            };
            this.$post('user/coach/updateBond', {
                id: this.coach_id,
                bond: '-' + this.form.bond,
                remark: this.form.remark,
                type: 2,
            }).then(res => {
                if (res.data.code == 0) {
                    this.$message({
                        type: "error",
                        message: '已扣除',
                    });
                    this.popup = false
                    this.getList()
                } else {
                    this.$message({
                        type: "error",
                        message: res.data.message,
                    });
                }
            })
        },
        GetListPageChild() {
            this.$axios({
                url: "/user/mechanismCategory/queryListPageChild",
                params: {
                    type: 2,
                    status: 2,
                    source: "课程",
                },
            }).then((res) => {
                this.listPageChild = res.data.data;
            });
        },
        toDetails(row) {
            this.$router.push({ name: "coachDetails", query: { id: row.id } });
        },
        //列表
        getList() {
            this.is_loading = true;
            let url = "/user/coach/queryManagerListPage";
            let params = {
                pageSize: 10,
                currentPage: this.currentPage,
                status: 2,
                sortName:"bond",
                name: this.name || null,
                phone: this.phone || null,
            };
            if (this.teaching_categories) {
                params.teaching_categories = this.teaching_categories;
            }
            this.$axios
                .get(url, {
                    params,
                })
                .then((res) => {
                    this.DataList = res.data.data.rows;
                    this.Total = res.data.data.total;
                    this.is_loading = false;
                });
        },
        search() {
            this.currentPage = 1;
            this.getList();
        },
        changeCurrentPage(v) {
            this.currentPage = v;
            this.getList();
        },
    },
};
</script>

<style lang="less">
.expand-form {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 20px;
    line-height: 1.5;

    .el-form-item {
        width: 40%;
    }
}
</style>
